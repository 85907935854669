import React, { Component } from 'react';

import { MdContentCopy } from 'react-icons/md'

import axios from 'axios'

interface LinodeType {
  id: string,
  label: string,
  memory: number,
  disk: number,
  transfer: number,
  vcpus: number,
  gpus: number,
  network_out: number,
  hourly: number,
  monthly: number,
}

interface Props {

}

interface State {
  classes: LinodeType[],
  minMemory: string,
  minDisk: string,
  minTransfer: string,
  minCpus: string,
  minGpus: string,
  minBandwidth: string,
  maxHourly: string,
  maxMonthly: string,
}



class Container extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      classes: [],
      minMemory: "",
      minDisk: "",
      minTransfer: "",
      minCpus: "",
      minGpus: "",
      minBandwidth: "",
      maxHourly: "",
      maxMonthly: "",
    }
  }

  componentDidMount() {
    axios.get("https://api.linode.com/v4/linode/types")
    .then(res => {
      let types = res.data.data

      let classes = []
      for (let i=0; i<types.length; i++) {
        let t = types[i]
        let nt = {
          id: t.id,
          label: t.label,
          memory: t.memory / 1024,
          disk: t.disk / 1024,
          transfer: t.transfer / 1000,
          vcpus: t.vcpus,
          gpus: t.gpus,
          network_out: t.network_out / 1000,
          hourly: t.price.hourly,
          monthly: t.price.monthly,
        }
        classes.push(nt)
      }

      this.setState({
        classes: classes,
      })
    })
    .catch(err => {
      console.log("Error fetching linode types: " + err)
      alert("Fetch failed")
    })
  }

  updateMemory(event: any) {
    this.setState({
      minMemory: event.target.value,
    })
  }

  updateDisk(event: any) {
    this.setState({
      minDisk: event.target.value,
    })
  }

  updateTransfer(event: any) {
    this.setState({
      minTransfer: event.target.value,
    })
  }

  updateCpus(event: any) {
    this.setState({
      minCpus: event.target.value,
    })
  }

  updateGpus(event: any) {
    this.setState({
      minGpus: event.target.value,
    })
  }

  updateBandwidth(event: any) {
    this.setState({
      minBandwidth: event.target.value,
    })
  }

  updateHourly(event: any) {
    this.setState({
      maxHourly: event.target.value,
    })
  }

  updateMonthly(event: any) {
    this.setState({
      maxMonthly: event.target.value,
    })
  }

  copyString(s: string) {
    navigator.clipboard.writeText(s)
  }

  render() {

    let classes = this.state.classes

    let minMemory = parseInt(this.state.minMemory)
    if (minMemory > 0) {
      classes = classes.filter(v => v.memory >= minMemory)
    }

    let minDisk = parseInt(this.state.minDisk)
    if (minDisk > 0) {
      classes = classes.filter(v => v.disk >= minDisk)
    }

    let minTransfer = parseInt(this.state.minTransfer)
    if (minTransfer > 0) {
      classes = classes.filter(v => v.transfer >= minTransfer)
    }

    let minCpus = parseInt(this.state.minCpus)
    if (minCpus > 0) {
      classes = classes.filter(v => v.vcpus >= minCpus)
    }

    let minGpus = parseInt(this.state.minGpus)
    if (minGpus > 0) {
      classes = classes.filter(v => v.gpus >= minGpus)
    }

    let minBandwidth = parseInt(this.state.minBandwidth)
    if (minBandwidth > 0) {
      classes = classes.filter(v => v.network_out >= minBandwidth)
    }

    let maxHourly = parseFloat(this.state.maxHourly)
    if (maxHourly > 0) {
      classes = classes.filter(v => v.hourly <= maxHourly)
    }

    let maxMonthly = parseInt(this.state.maxMonthly)
    if (maxMonthly > 0) {
      classes = classes.filter(v => v.monthly <= maxMonthly)
    }

    let inputStyle = {
      maxWidth: 60,
    }

    let dataStyle = {
      display: "flex",
      justifyItems: "center",
      justifyContent: "center",
    }

    let min = "Min"
    let max = "Max"

    return (
      <div>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Memory (GB)</th>
                <th>Disk (GB)</th>
                <th>Transfer (GB)</th>
                <th>CPUs</th>
                <th>GPUs</th>
                <th>Out bandwidth (Gbits)</th>
                <th>Hourly ($)</th>
                <th>Monthly ($)</th>
              </tr>
              <tr>
                <th></th>
                <th><input type="text" name="memory" placeholder={min} value={this.state.minMemory} onChange={e => this.updateMemory(e)} style={inputStyle}/></th>
                <th><input type="text" name="disk" placeholder={min} value={this.state.minDisk} onChange={e => this.updateDisk(e)} style={inputStyle}/></th>
                <th><input type="text" name="transfer" placeholder={min} value={this.state.minTransfer} onChange={e => this.updateTransfer(e)} style={inputStyle}/></th>
                <th><input type="text" name="cpus" placeholder={min} value={this.state.minCpus} onChange={e => this.updateCpus(e)} style={inputStyle}/></th>
                <th><input type="text" name="gpus" placeholder={min} value={this.state.minGpus} onChange={e => this.updateGpus(e)} style={inputStyle}/></th>
                <th><input type="text" name="bandwidth" placeholder={min} value={this.state.minBandwidth} onChange={e => this.updateBandwidth(e)} style={inputStyle}/></th>
                <th><input type="text" name="hourly" placeholder={max} value={this.state.maxHourly} onChange={e => this.updateHourly(e)} style={inputStyle}/></th>
                <th><input type="text" name="monthly" placeholder={max} value={this.state.maxMonthly} onChange={e => this.updateMonthly(e)} style={inputStyle}/></th>
              </tr>
            </thead>
            <tbody>
              {classes.map(v => {
                return (
                  <tr key={v.id}>
                    <td>{v.id}<MdContentCopy style={{paddingLeft: 3, cursor: "pointer"}} onClick={() => this.copyString(v.id)}/></td>
                    <td><div style={dataStyle}>{v.memory}</div></td>
                    <td><div style={dataStyle}>{v.disk}</div></td>
                    <td><div style={dataStyle}>{v.transfer}</div></td>
                    <td><div style={dataStyle}>{v.vcpus}</div></td>
                    <td><div style={dataStyle}>{v.gpus}</div></td>
                    <td><div style={dataStyle}>{v.network_out}</div></td>
                    <td><div style={dataStyle}>{v.hourly}</div></td>
                    <td><div style={dataStyle}>{v.monthly}</div></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
      </div>
    );
  }
}

export default Container;